import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "button"];

  connect() {
    this.buttonTarget
    this.menuTarget;
  }


  toggleMenu() {
    this.menuTarget.classList.toggle("hidden");
  }
}