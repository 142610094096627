// check dependencies for each jquery-ui module here to avoid loading the whole library => https://jqueryui.com/download/#!version=1.13.2&components=101000000100000010000000010000000000000000000000
import "jquery-ui/ui/version";
import "jquery-ui/ui/plugin";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/data";
import "jquery-ui/ui/scroll-parent";
import "jquery-ui/ui/widgets/mouse";
import "jquery-ui/ui/widgets/sortable";

!(function (n) {
  "use strict";
  function e() {
    n("#hamburger-menu").toggleClass("active"),
      n("#main-navigation").toggleClass("show"),
      n("body").toggleClass("lock-body");
  }
  function o() {
    n("#mobile-header").is(":visible") &&
      n("#main-navigation").hasClass("show") &&
      e();
  }
  function initializeCalendly() {
    let script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.onload = function () {
      if (window.hasOwnProperty("Calendly")) {
        Calendly.initInlineWidget({
          url: "https://calendly.com/groupe-quinze/rendez-vous-site",
          parentElement: document.getElementById("calendlyContainer"),
          prefill: {},
          utm: {},
        });
      }
    };
    document.body.appendChild(script);
  }
  n(document).on("turbo:load", function () {
    // n("#hamburger-menu").on("click", e),
    n("#main-content").on("click", o),
      n(".button--read-more").on("click", function (e) {
        e.preventDefault(),
          n(this).parent().find(".d-none").removeClass("d-none"),
          n(this).addClass("d-none");
      });

    const DROPPABLE_STATES = ["started", "pending", "thinking"];
    const DROPPABLE_TYPES = {
      documents: "document",
      missions: "mission",
      notes: "note",
      tasks: "patrimony_task",
      projects: "mission",
    };
    n(".droppable .tasks-column-container").sortable({
      connectWith: ".connectedSortable .tasks-column-container",
      start: function (event, ui) {},
      update: function (event, ui) {
        if (ui.sender) {
          ui.item
            .parent()
            .parent()
            .find("h2 .circle-number")
            .text(function (index, text) {
              return parseInt(text, 10) + 1;
            });
          ui.sender
            .parent()
            .find("h2 .circle-number")
            .text(function (index, text) {
              return parseInt(text, 10) - 1;
            });
          let url = new URL(ui.item.attr("href"), location);
          const stateIndex = $(".tasks-column-container").index(
            ui.item.parent()
          );
          const formData = new FormData();
          let droppableType = "";
          if (url.pathname.includes("missions")) {
            const badge = ui.item.find(
              ".card-task__header .badges.badges__small"
            );
            url.pathname = url.pathname + "/update_state";
            if (badge.hasClass("badges__project")) {
              droppableType = DROPPABLE_TYPES.projects;
            } else {
              droppableType = DROPPABLE_TYPES.missions;
            }
          } else if (url.pathname.includes("documents")) {
            droppableType = DROPPABLE_TYPES.documents;
          } else if (url.pathname.includes("notes")) {
            droppableType = DROPPABLE_TYPES.notes;
          } else if (url.pathname.includes("tasks")) {
            droppableType = DROPPABLE_TYPES.tasks;
          }
          formData.append(
            droppableType + "[state]",
            DROPPABLE_STATES[stateIndex]
          );
          n.ajax({
            url: url.toString(),
            method: "PUT",
            data: formData,
            processData: false,
            contentType: false,
          });
        }
      },
    });

    const defaultAllowList = bootstrap.Tooltip.Default.allowList;
    defaultAllowList.a = [
      "target",
      "href",
      "title",
      "rel",
      "data-confirm",
      "data-method",
      "data-turbo",
      "data-disable-with",
    ];

    if (document.querySelector(".menu-popover")) {
      const popover = new bootstrap.Popover(
        document.querySelector(".menu-popover"),
        {
          trigger: "focus",
          container: "body",
          html: true,
          content: function () {
            return this.nextElementSibling.innerHTML;
          },
          template: `
          <div class="popover" role="tooltip">
            <div class="popover-arrow"></div>
            <div class="popover-body">
            
            </div>
          </div>
        `,
          offset: [0, 40],
          popperConfig: {
            placement: "right-end",
          },
        }
      );
    }

    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    if (document.getElementById("calendlyContainer")) {
      initializeCalendly();
    }

    // function setStateBodyColor(state) {
    //   document.body.classList.remove("page-purple");
    //   document.body.classList.remove("page-soft-blue");
    //   document.body.classList.remove("page-green");
    //   switch (state) {
    //     case "started":
    //       document.body.classList.add("page-purple");
    //       break;
    //     case "pending":
    //       document.body.classList.add("page-soft-blue");
    //       break;
    //     case "thinking":
    //       document.body.classList.add("page-green");
    //       break;
    //     default:
    //       document.body.classList.add("page-soft-blue");
    //       break;
    //   }
    // }

    // if (document.querySelector("select#patrimony_task_state")) {
    //   document
    //     .querySelector("select#patrimony_task_state")
    //     .addEventListener("change", function (e) {
    //       setStateBodyColor(e.target.value);
    //     });

    //   const params = new URLSearchParams(document.location.search);
    //   if (params.get("state")) {
    //     setStateBodyColor(params.get("state"));
    //   }
    // }

    if (document.getElementById("patrimony_task_files")) {
      document
        .getElementById("patrimony_task_files")
        .addEventListener("change", function (e) {
          if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
              document.getElementById("documents_preview").innerHTML += `
                <div class='col-12 col-md-6 col-lg-4'>
                  <div class='form-file-upload--display'>
                    <span class='icon-file-text'></span>
                    <span class='filename purple'>${e.target.files[i].name}</span>
                    <a class='btn btn-danger btn-sm btn-icon new-file-remove' data-index='${i}'>
                      <span class='fa fa-trash'></span>
                    </a>
                  </div>
                </div>
              `;
            }
            const removeBtns = document.querySelectorAll('.new-file-remove')
            removeBtns.forEach(function (btn) { 
              btn.addEventListener('click', function(evt) {
                document.getElementById('patrimony_task_files').value = null;
                removeBtns.forEach(function (btn) {
                  btn.closest('.col-12').remove()
                })
              })
            })
          }
        });
    }
  });
})(jQuery);
