import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select.popular";

// Connect to input with data-controller="document-file"
export default class extends Controller {
  static values = {
    url: String,
    value: String,
  };

  static targets = [
    "input",
    "fileInput",
    "titleInput",
    "dateInput",
    "hiddenFieldsContainer",
    "hiddenFieldContainer",
  ];

  connect() {
    this.isEditMode = window.location.pathname.endsWith("edit");
    this.input = new TomSelect(this.inputTarget, {
      create: false,
      maxItems: this.inputTarget.multiple ? null : 1,
      preload: true,
      placeholder:
        "Ajouter une document de votre bibliothèque patrimoniale",
      valueField: "id",
      labelField: "name",
      searchField: "name",
      plugins: {
        remove_button: {
          title: "Retirer ce choix",
        },
      },
      render: {
        item: function (data, escape) {
          return (
            '<div class="item" data-created-at="' +
            escape(data.createdAt) +
            '">' +
            escape(data.name) +
            "</div>"
          );
        },
        option_create: (data, escape) => {
          return (
            '<div class="create">Ajouter <strong>' +
            escape(data.input) +
            "</strong>&hellip;</div>"
          );
        },
        no_results: (data, escape) => {
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      },
      load: (query, callback) => {
        const csrfToken = document.querySelector("[name='csrf-token']").content;
        fetch(this.urlValue, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search: encodeURIComponent(query),
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            callback(json.items);
          })
          .catch(() => {
            callback();
          });
      },
    });

    this.input.on("change", (value) => this.handleSelectChange(value));
    this.input.on("item_add", (value, item) =>
      this.handleSelectItemAdd(value, item)
    );
    this.input.on("item_remove", (value, $item) =>
      this.handleSelectItemRemove(value, $item)
    );
  }

  handleSelectChange(value) {
    if (value) {
      this.fileInputTarget.value = null;
      this.valueValue = value;
    }
  }

  handleSelectItemAdd(value, item) {
    if (this.hasDateInputTarget) {
      if (!this.dateInputTarget.value) {
        this.dateInputTarget._flatpickr.setDate(item.dataset.createdAt);
      }
    }
    if (this.hasTitleInputTarget) {
      if (!this.titleInputTarget.value) {
        this.titleInputTarget.value = item.textContent.slice(0, -1);
      }
    }
    if (value && !this.isEditMode) {
      if (this.hasHiddenFieldsContainerTarget) {
        this.hiddenFieldsContainerTarget.classList.remove("invisible");
        this.hiddenFieldsContainerTarget.classList.add("show");
        this.hiddenFieldContainerTarget.classList.remove("show");
        this.hiddenFieldContainerTarget.classList.add("invisible");
      }
    }
  }

  handleSelectItemRemove(value, item) {
    if (!this.isEditMode) {
      if (this.hasHiddenFieldsContainerTarget) {
        this.hiddenFieldsContainerTarget.classList.remove("show");
        this.hiddenFieldsContainerTarget.classList.add("invisible");
      }
      if (this.hasTitleInputTarget) {
        this.titleInputTarget.value = "";
      }
      if (this.hasDateInputTarget) {
        this.dateInputTarget.value = "";
      }
    }
  }

  handleChange(event) {

    console.log(this.hasTitleInputTarget);

    this.input.clear();
    if (this.hasTitleInputTarget) {
      if (!this.titleInputTarget.value || this.titleInputTarget.value !== event.target.files[0].name) {
        this.titleInputTarget.value = event.target.files[0].name;
      }
    }
    if (!this.isEditMode) {
      if (event.target.files[0]) {
        if (this.hasHiddenFieldsContainerTarget) {
          this.hiddenFieldsContainerTarget.classList.remove("invisible");
          this.hiddenFieldsContainerTarget.classList.add("show");
          this.hiddenFieldContainerTarget.classList.remove("invisible");
          this.hiddenFieldContainerTarget.classList.add("show");
        }
      } else {
        if (this.hasHiddenFieldsContainerTarget) {
          this.hiddenFieldsContainerTarget.classList.remove("show");
          this.hiddenFieldsContainerTarget.classList.add("invisible");
        }
        this.titleInputTarget.value = "";
        if (this.hasDateInputTarget) {
          this.dateInputTarget.value = "";
        }
        this.hiddenFieldContainerTarget.classList.remove("show");
        this.hiddenFieldContainerTarget.classList.add("invisible");
      }
    }
  }

  disconnect() {
    this.input.destroy();
  }
}
