import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select.popular";

// Connects to data-controller="ts--user-select"
export default class extends Controller {
  static targets = ["content", "editContent", "select", "emptyState"];
  static values = {
    editMode: { type: Boolean, default: false },
  };

  connect() {
    this.select = new TomSelect(this.selectTarget, {
      plugins: {
        remove_button: {
          title: "Retirer ce choix",
        },
      },
      render: {
        option: function (data, escape) {
          if (data.initials && data.initials.length > 0) {
            let image;
            if (data.image && data.image.length > 0) {
              image = `<img src="${data.image}" width="32" height="32" class="rounded-circle img-fluid me-3" />`;
            } else {
              image = `<div class="profile-image me-3" style="width: 32px; height: 32px;"><p class="fw-bold mb-0">${data.initials}</p></div>`;
            }
            return (
              "<div class='d-flex align-items-center'>" +
              image +
              escape(data.text) +
              "</div>"
            );
          } else {
            return "<div>" + escape(data.text) + "</div>";
          }
        },
        no_results: function (data, escape) {
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      },
    });

    this.select.on("blur", () => {
      if (this.editModeValue) {
        this.toggle();
      }
    });

    this.select.on("item_add", (value) => {
      if (this.hasEmptyStateTarget) {
        this.emptyStateTarget.classList.add("d-none");
      }
      const optionHtml = this.select.getOption(value);
      const tmpOption = optionHtml.cloneNode(true);
      tmpOption.querySelectorAll("*").forEach(function (el) {
        el.remove(); // remove the children
      });
      const profileImage = optionHtml
        .querySelector(".profile-image, img")
        .cloneNode(true);
      profileImage.setAttribute("data-value", value);
      profileImage.setAttribute("data-bs-placement", "top");
      profileImage.setAttribute("data-bs-toggle", "tooltip");
      profileImage.setAttribute(
        "data-bs-original-title",
        tmpOption.textContent
      );
      profileImage.classList.remove("me-3");
      profileImage.classList.add("me-2");
      this.contentTarget
        .querySelector(".form-control")
        .appendChild(profileImage);
      new bootstrap.Tooltip(profileImage);
      this.toggle();
    });

    this.select.on("item_remove", (value) => {
      this.contentTarget.querySelector(`[data-value="${value}"]`).remove();
      if (this.contentTarget.querySelectorAll("[data-value]").length === 0) {
        if (this.hasEmptyStateTarget) {
          this.emptyStateTarget.classList.remove("d-none");
        }
      }
      this.toggle();
    });
  }

  toggle() {
    this.contentTarget.classList.toggle("d-none");
    this.editContentTarget.classList.toggle("d-none");
    if (!this.editModeValue) {
      this.select.open();
    }
    this.editModeValue = !this.editModeValue;
  }
}
